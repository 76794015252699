import Template from "pages/Home/components/Template";

import Item from "components/Modules/OfferCard";
import Slider from "components/Modules/Slider";
import { useTranslation } from "next-i18next";
import useDynamicLink from "hooks/useDynamicLink";

const Specials = ({ data }) => {
  const { t } = useTranslation("home");
  const { t: tNavigation } = useTranslation("navigation");
  const { generateLink } = useDynamicLink();

  if (!data) return null;

  return (
    <Template
      title={t("Check out special offers.title")}
      subTitle={t("Check out special offers.desc")}
      text={t("Looking for something exceptional...")}
      backgroundColor="secondary"
      id={"specials"}
    >
      <Slider color="secondary">
        {data?.map((el) => {
          const lintGen = generateLink({
            elements: [el?.city_name, el?.quarter_name],
            uuid: el?.uuid,
          });
          return (
            <Item
              key={el?.uuid}
              {...el}
              navigate={`/${tNavigation("navigationPaths.search")}/${
                lintGen?.valid ? lintGen.link : el?.uuid
              }`}
              image={
                el?.files?.[0]?.file
                  ? `${process.env.BASE_PLAN_URL}${el?.files?.[0]?.file}`
                  : null
              }
            />
          );
        })}
      </Slider>
    </Template>
  );
};

export default Specials;
